import React from 'react';
import AppContext from '../context';
import axios from 'axios';

export const useCart = () => {
  const { cartItems, setCartItems } = React.useContext(AppContext);
  const totalPrice = cartItems.reduce((sum, obj) => Number(obj.price)*obj.count + sum, 0);
  const totalPieces = cartItems.reduce(
    (sum, obj) => Number(obj.count) + sum,
    0
  );
  const paramsTable1 = { table: 'cart' };
  const updateCartItems = async () => {
    try {
      const response = await axios.get('https://bagshop.pugachova.site/api/cart', {
              params: paramsTable1,
            })
      const updatedCartItems = response.data; // Предполагается, что данные приходят в нужном формате
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error('Ошибка при получении данных', error);
    }
  };
  return { cartItems, setCartItems, totalPrice, updateCartItems, totalPieces };
};
