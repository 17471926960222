import React from 'react';

const OrderItem = ({ name, src, price, count }) => {
  return (
    <div className='orderItem'>
      <div>
        <img
          src={src}
          alt='img'
        />
      </div>
      <div className='orderItem__box'>
        <p>{name}</p>
        <div className='orderItem__descr'>
          <div style={{ display: 'flex' }}>
            <p>{price} UAH</p>
            <p className='orderItem__multiply'>x</p>
            <p>
              {count} {count === '1' ? 'pc' : 'pcs'}
            </p>
          </div>
          <p className='orderItem__price'>{price * count} UAH</p>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
