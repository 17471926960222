import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';

import Drawer from './components/Drawer/Drawer';
import Header from './components/Header/Header';
import Selected from './pages/Selected';
import Home from './pages/Home';
import AppContext from './context';
import Orders from './pages/Orders';

function App() {
  function getCurrentFormattedDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;
    return formattedDate;
  }
  const currentFormattedDate = getCurrentFormattedDate();

  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [cartOpened, setCartOpened] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [cartClosedId, setCartClosedId] = useState('');
  const [orderNum, setOrderNum] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const paramsTable1 = { table: 'cart' };
  const paramsTable2 = { table: 'selected' };
  const paramsTable3 = { table: 'orders' };
  const paramsTable4 = { table: 'order_items' };
  const paramsTable5 = { table: 'items' };
  const updateOrderData = async () => {
    const [orderResponse, orderItemsResponse] = await Promise.all([
      axios.get('https://bagshop.pugachova.site/api/orders', {
        params: paramsTable3,
      }),
      axios.get('https://bagshop.pugachova.site/api/ordersItems', {
        params: paramsTable4,
      }),
    ]);
    setOrders(orderResponse.data);
    setOrderItems(orderItemsResponse.data);
    setOrderNum(orderResponse.data.length + 1);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);

        const [
          cartResponse,
          selectedResponse,
          orderResponse,
          orderItemsResponse,
          itemsResponse,
        ] = await Promise.all([
          axios.get('https://bagshop.pugachova.site/api/cart', {
            params: paramsTable1,
          }),
          axios.get('https://bagshop.pugachova.site/api/selected', {
            params: paramsTable2,
          }),
          axios.get('https://bagshop.pugachova.site/api/orders', {
            params: paramsTable3,
          }),
          axios.get('https://bagshop.pugachova.site/api/ordersItems', {
            params: paramsTable4,
          }),
          axios.get('https://bagshop.pugachova.site/api/items', {
            params: paramsTable5,
          }),
        ]);

        setCartItems(cartResponse.data);
        setSelectedItems(selectedResponse.data);
        setOrders(orderResponse.data);
        setOrderItems(orderItemsResponse.data);
        setOrderNum(orderResponse.data.length + 1);
        setItems(itemsResponse.data);
      } catch (error) {
        console.error(
          'An error occurred while fetching data: моя ошибочка',
          error
        );
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const onAddToCart = async (obj) => {

    try {
      const findItem = cartItems.find(
        (cartItem) => Number(cartItem.parentId) === Number(obj.parentId)
      );
      if (findItem) {
        await axios
          .delete(
            'https://bagshop.pugachova.site/api/cart?id=' +
              findItem.id +
              '&table=cart'
          ).catch(function (error) {
            console.log(error, 'ошибка в удалении cart');
          });
        setCartItems((prev) =>
          prev.filter((item) => Number(item.parentId) !== Number(obj.parentId))
        );
      } else {
        setCartItems((prev) => [...prev, obj]);

        const { data: responseData } = await axios
          .post('https://bagshop.pugachova.site/api/cart', {
            table: 'cart',
            data: obj,
          })
          .then(function (response) {
            return response.data;
          })
          .catch(function (error) {
            console.log(error, 'ошибка');
          });

        setCartItems((prev) =>
          prev.map((item) => {
            if (item.parentId === responseData.parentId) {
              return {
                ...item,
                id: responseData.id,
              };
            }
            return item;
          })
        );
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onAddToCarrrt',
        error
      );
    }
  };
  const onRemoveCartItem = (id, parentId) => {
    try {
      axios.delete(
        'https://bagshop.pugachova.site/api/cart?id=' + id + '&table=cart'
      );

      setCartItems((prev) => prev.filter((item) => item.id !== id));
      setCartClosedId(parentId);
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onRemoveCartItem',
        error
      );
    }
  };
  const onAddToSelected = async (obj) => {
    try {
      const findItem = selectedItems.find(
        (favObj) => Number(favObj.parentId) === Number(obj.id)
      );
      if (findItem) {
        await axios
          .delete(
            'https://bagshop.pugachova.site/api/selected?id=' +
              findItem.id +
              '&table=selected'
          ).catch(function (error) {
            console.log(error, 'ошибка в удалении selected');
          });
        setSelectedItems((prev) =>
          prev.filter((item) => Number(item.parentId) !== Number(obj.id))
        );
      } else {
        setSelectedItems((prev) => [...prev, obj]);
        const { data } = await axios.post(
          'https://bagshop.pugachova.site/api/selected',
          {
            table: 'selected',
            data: obj,
          }
        );
        const innerData = data.data;

        setSelectedItems((prev) =>
          prev.map((item) => {
            if (item.parentId === innerData.parentId) {
              return {
                ...item,
                id: innerData.id,
              };
            }
            return item;
          })
        );
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onAddToSelected',
        error
      );
    }
  };
  const deleteFromSelected = async (obj) => {
    try {
      await axios
        .delete(
          'https://bagshop.pugachova.site/api/selected?id=' +
            obj.id +
            '&table=selected'
        ).catch(function (error) {
          console.log(error, 'ошибка в удалении selected');
        });
      setSelectedItems((prev) =>
        prev.filter((item) => Number(item.id) !== Number(obj.id))
      );
    } catch (error) {
      console.error(
        'An error occurred while fetching data: deleteFromSelected',
        error
      );
    }
  };

  function onChangeSearchInput(event) {
    setSearchValue(event.target.value);
  }

  return (
    <AppContext.Provider
      value={{
        items,
        cartItems,
        selectedItems,
        orders,
        setCartOpened,
        setCartItems,
        isOrderAccepted,
        setIsOrderAccepted,
        currentFormattedDate,
        orderNum,
        setOrderNum,
        orderItems,
        updateOrderData,
      }}
    >
      <div className='App'>
        <Drawer
          closeDrawer={() => {
            setCartOpened(false);
          }}
          onRemove={onRemoveCartItem}
          opened={cartOpened}
        />
        <Header
          onCartClick={() => {
            setCartOpened(true);
          }}
        />

        <Routes>
          <Route
            path='/'
            exact
            element={
              <Home
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                onChangeSearchInput={onChangeSearchInput}
                onAddToSelected={onAddToSelected}
                onAddToCart={onAddToCart}
                cartClosedId={cartClosedId}
                isLoading={isLoading}
              />
            }
          />
          <Route
            path='/selected'
            element={
              <Selected
                onAddToSelected={onAddToSelected}
                onAddToCart={onAddToCart}
                cartClosedId={cartClosedId}
                deleteFromSelected={deleteFromSelected}
                isLoading={isLoading}
              />
            }
          />
          <Route
            path='/orders'
            element={
              <Orders
                isLoading={isLoading}
              />
            }
          />
        </Routes>
      </div>
    </AppContext.Provider>
  );
}

export default App;
