import React, { useState } from 'react';
import s from './Drawer.module.scss';
import { useCart } from '../../hooks/useCart';
import axios from 'axios';

const DrawerItems = ({ id, src, name, price, parentId, onRemove, countI }) => {
  const [count, setCount] = useState(Number(countI));

 const { updateCartItems } = useCart();
 
  const incrItemCount = async (id) => {
    try {
      setCount((prevCount) => parseInt(prevCount, 10) + 1);
      await axios.put(`https://bagshop.pugachova.site/api/cart`, {
        id: id,
        count: count + 1,
      });
      updateCartItems();
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onClickCount',
        error
      );
    }
  };

  const decrItemCount = async (id) => {
    try {
      if (count > 1) {
        setCount((prevCount) => parseInt(prevCount, 10) - 1);
        await axios.put(`https://bagshop.pugachova.site/api/cart`, {
          id: id,
          count: count - 1,
        });
        updateCartItems();
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onClickCount',
        error
      );
    }
  };

  return (
    <div className={s.drawerItem} key={id}>
      <img className={s.drawerImg} src={src} alt='bag' />
      <div className={s.itemCount__box}>
        <p>{name}</p>
        <div>
          <span
            onClick={() => {
              decrItemCount(id);
            }}
            style={{
              border: count === 1 ? '1px solid #efefef' : '1px solid #8c8c8c',
            }}
            className={s.decrItemCount}
          >
            -
          </span>
          <span className={s.itemCount}>{count}</span>
          <span
            onClick={() => {
              incrItemCount(id);
            }}
            className={s.incrItemCount}
          >
            +
          </span>
          <span className={s.priceValue}>{price * count} UAH</span>
        </div>
      </div>
      <img
        className={s.closeBtn}
        src='/images/close.svg'
        alt='closeBtn'
        onClick={() => onRemove(id, parentId)}
      />
    </div>
  );
};

export default DrawerItems;
