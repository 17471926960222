import React from 'react';
import AppContext from '../context';
import { Link } from 'react-router-dom';

const Info = ({ title, image, description }) => {
  const { setCartOpened, isOrderAccepted, setIsOrderAccepted, orderNum } =
    React.useContext(AppContext);
  
  return (
    <div className='drawerEmpty'>
      <img
        className='drawerEmptyImg'
        style={{
          width: isOrderAccepted ? 50 : 100,
          height: isOrderAccepted ? 50 : 100,
        }}
        src={image}
        alt=''
      />
      {isOrderAccepted ? (
        <h3 className='drawerEmptyTitle'>Order No.{orderNum - 1}</h3>
      ) : null}

      <h3 className='drawerEmptyTitle'>{title}</h3>
      <p className='drawerEmptyDescription'>{description}</p>
      <Link
        className='btn'
        to='/'
        onClick={() => {
          setCartOpened(false);
          setIsOrderAccepted(false);
        }}
      >
        back to products
      </Link>
    </div>
  );
};

export default Info;
