import React from 'react'
import Card from '../components/Card/Card';
import AppContext from '../context';
 
function Home({
  searchValue,
  setSearchValue,
  onChangeSearchInput,
  onAddToSelected,
  onAddToCart,
  cartClosedId,
  isLoading
}) {
  const { items, selectedItems, cartItems } = React.useContext(AppContext);
  const renderItems =()=>{
    const filtredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    return (
      isLoading
        ? [...Array(8)]
        : filtredItems
    ).map((item, i) => {

      return (
        <Card
          {...item}
          parentId={isLoading ? i : item.id}
          key={isLoading ? i : item.id}
          onSelected={(obj) => onAddToSelected(obj)}
          onPlus={(obj) => onAddToCart(obj)}
          added={cartItems.some((obj) => obj.parentId === item.id)}
          selected={selectedItems.some((obj) => obj.parentId === item.id)}
          cartClosedId={cartClosedId}
          loading={isLoading}
        />
      );
    });
    
  }

  return (
    <div className='content'>
      <div className='search-box'>
        
          <h1 className='search-title'>
            {searchValue ? `Search "${searchValue}"` : 'All bags'}
          </h1>

          <div className='search-block'>
            <img src='/images/search.png' alt='search' />
            {searchValue && (
              <img
                className='clear'
                src='/images/clear.png'
                alt='clear'
                onClick={() => setSearchValue('')}
              />
            )}
            <input
              type='text'
              placeholder='Search'
              value={searchValue}
              onChange={onChangeSearchInput}
            />
          </div>
      </div>
      <div className='cards'>{renderItems()}</div>
    </div>
  );
};

export default Home