import React from 'react';
import axios from 'axios';

import AppContext from '../../context';
import Info from '../Info';
import { useCart } from '../../hooks/useCart';

import s from './Drawer.module.scss';
import DrawerItems from './DrawerItems';
import { useEffect } from 'react';

function Drawer({ closeDrawer, onRemove, opened }) {
  const {
    isOrderAccepted,
    setIsOrderAccepted,
    setOrderNum,
    currentFormattedDate,
    orderNum,
    updateOrderData,
  } = React.useContext(AppContext);
  const { cartItems, setCartItems, totalPrice, totalPieces } = useCart();
  useEffect(() => {}, [totalPrice]);


  const onClickOrder = async () => {
    try {
      const { data: responseData } = await axios
        .post('https://bagshop.pugachova.site/api/orders', {
          table: 'orders',
          date: currentFormattedDate,
          items: cartItems,
          pieces: totalPieces,
          price: totalPrice,
          orderNum: orderNum,
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error, 'ошибка');
        });
      setIsOrderAccepted(true);
      setCartItems([]);
      setOrderNum((prev) => prev + 1);
      axios
        .delete('https://bagshop.pugachova.site/api/cart', {
          params: {
            table: 'cart', 
          },
        }).catch((error) => {
          console.error('Ошибка:удаляем всю корзину', error);
        });
      updateOrderData();
    } catch (error) {
      console.error(
        'An error occurred while fetching data: onClickOrder',
        error
      );
    }
  };
  const onCartCloseClick = () => {
    closeDrawer();
    setIsOrderAccepted(false);
  };

  return (
    <div className={`${s.overlay} ${opened ? s.overlayVisible : ''}`}>
      <div className={s.drawer}>
        <h2>
          Cart
          <img
            className={s.closeBtn}
            src='/images/close.svg'
            alt='closeBtn'
            onClick={onCartCloseClick}
          />
        </h2>
        {cartItems.length > 0 ? (
          <div className={s.drawerItemsBox}>
            <div className={s.drawerItems}>
              {cartItems.map((obj) => {
                return (
                  <DrawerItems
                    key={obj.parentId}
                    onRemove={onRemove}
                    id={obj.id}
                    src={obj.src}
                    name={obj.name}
                    price={obj.price}
                    parentId={obj.parentId}
                    countI={obj.count}
                  />
                );
              })}
            </div>
            <div className={s.drawerTotal}>
              <div className={s.drawerTotalItem}>
                <p>Total:</p>
                <p></p>
                <p className={s.drawerPrice}>
                  {totalPrice}
                  <span>UAH</span>
                </p>
              </div>
              <button className='btn' onClick={onClickOrder}>
                checkout
              </button>
            </div>
          </div>
        ) : (
          <Info
            title={
              isOrderAccepted ? 'Your order is accepted' : 'The basket is empty'
            }
            description={
              isOrderAccepted
                ? "We'll be in touch soon!"
                : "But it's never too late to fix it!"
            }
            image={
              isOrderAccepted ? './images/tick.png' : './images/cart-empty.png'
            }
          />
        )}
      </div>
    </div>
  );
}

export default Drawer;
