import React from 'react';
import Card from '../components/Card/Card';
import AppContext from '../context';
import { Link } from 'react-router-dom';
import OrderItem from './OrderItem';

const Orders = ({ isLoading = 'true' }) => {
  const { orders, orderItems } = React.useContext(AppContext);

  return (
    <div className='content'>
      <div className='contentBox'>
        <h1>My orders</h1>
      </div>

      {
        isLoading ? (
          <>
            <div className='orderTitleBox'>
              <div className='orderTitle'></div>
              <div className='orderTitle'></div>
            </div>
            <div className='cards orderCards'>
              {[...Array(8)].map((obj, i) => {
                return (
                  <Card
                    {...obj}
                    parentId={i}
                    key={i}
                    display='none'
                    loading={isLoading}
                  />
                );
              })}
            </div>
          </>
        ) : orders.length > 0 ? (
          orders.map((obj, i) => {
            return (
              <div className='order' key={i}>
                <div className='orderTitleBox'>
                  <div>
                    <div className='orderTitle'>Order #{obj.order_num}</div>
                    <div className='orderTitle'>{obj.date}</div>
                  </div>
                  <div>TOTAL: {obj.price} UAH</div>
                </div>
                <div>
                  {orderItems
                    .filter((item) => item.order_num === obj.order_num)
                    .map((item, i) => {
                      return (
                        <OrderItem
                          name={item.name}
                          src={item.src}
                          price={item.price}
                          count={item.count}
                        />
                      );
                    })}
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className='noOrdersBox'>
              <img
                className='noOrdersImg'
                src='./images/orders.png'
                alt='img'
              ></img>
              <div className='noOrdersDescr'>No Order Yet</div>
              <div className='noOrdersDescr'>
                But it's never too late to fix it!
              </div>
              <Link className='btn orderBtn' to='/'>
                back to products
              </Link>
            </div>
          </>
        )
      }
    </div>
  );
};

export default Orders;
